import React, { FC } from 'react';
import logo from '../assets/logo-side-menu.svg';
import home from '../assets/home-new.svg';
import agency from '../assets/agency-new.svg';
import user from '../assets/user-new.svg';
import accounting from '../assets/accounting-new.svg';
import reload from '../assets/recharging-new.svg';
import euro from '../assets/euro.svg';
import gear from '../assets/gear.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, makeStyles } from '@material-ui/core';
import StyledSideBarMenuListItem from '../Style/MuiStyles/SideBarListItem';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../Stores/Agencies.store';
import SvgIcon from '../Component/SvgIcon/SvgIcon';

interface MenuLinkProps {
  title: string;
  linkTo: string;
  iconName: string;
  width?: number;
  height?: number;
}

const MenuLink: FC<MenuLinkProps> = ({ title, linkTo, iconName, width, height }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [, firstSegment] = location.pathname.split('/');
  return (
    <StyledSideBarMenuListItem
      onClick={() => navigate(linkTo)}
      button key={title}
      isCurrentPage={`/${firstSegment}` === linkTo}
    >
      <SvgIcon icon={iconName} width={width || 32} height={height || 32}/>
      <div style={{ padding: '0 10px 0 10px' }}>{title}</div>
    </StyledSideBarMenuListItem>);
};

const SidebarMenuComponent = observer(() => {
  const agenciesStore = useInstance(AgenciesStore);
  const navigate = useNavigate();
  const styles = useStyles();

  return (
    <div className={styles.menuWrapper}>
      <img className={styles.logo} alt="logo" src={logo} onClick={() => navigate('/dashboard')}/>
      <List style={{ width: '100%' }}>
        <MenuLink title="Accueil" linkTo="/dashboard" iconName={home} width={25} height={25}/>
        {agenciesStore.currentAgency && agenciesStore.currentAgency.contractType !== 'PUBLIC_MEAL_SUBSIDY'
          ? <MenuLink title="Rechargement des droits" linkTo="/reloading" iconName={reload} width={25} height={25}/>
          : <></>
        }
        <MenuLink title="Entreprise" linkTo="/agency" iconName={agency} width={25} height={25}/>
        <MenuLink title="Collaborateurs" linkTo="/beneficiaries" iconName={user} width={25} height={25}/>
        <MenuLink title="Paramètres" linkTo="/settings" iconName={gear} width={25} height={25}/>
        {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType === 'PUBLIC_MEAL_SUBSIDY'
          ? <MenuLink title="Factures conventions" linkTo="/vouchers-invoices" iconName={accounting}/>
          : <></>
        }
        {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType !== 'PUBLIC_MEAL_SUBSIDY'
          ? <MenuLink title="Factures" linkTo="/accounting" iconName={accounting}/>
          : <></>
        }
        {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType !== 'PUBLIC_MEAL_SUBSIDY' && agenciesStore.currentAgency.isManagedPaymentMode === false &&
            <MenuLink title="Remboursements" linkTo="/paymentrequest" iconName={euro} width={25} height={25}/>
        }
        {agenciesStore.currentAgency && agenciesStore.currentAgency?.contractType === 'PUBLIC_MEAL_SUBSIDY' &&
            <MenuLink title="Transactions" linkTo="/vouchers-transactions" iconName={user} width={25} height={25}/>
        }
      </List>
    </div>
  );
});

export default SidebarMenuComponent;

const useStyles = makeStyles({
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '150px',
    paddingTop: '35px',
    paddingBottom: '50px',
  },
  logo: {
    width: '111px',
    marginTop: '3rem',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
});
