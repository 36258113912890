const settings = {
  //important words
  agencyAdmins: 'Utilisateurs',
  managementUnit: 'Unité de gestion',
  managementUnits: 'Unités de gestion',
  profile: 'Profil',
  profiles: 'Profils',
  functionalProfiles: 'Profils fonctionnels',
  addEmpUserSubTitle: 'Ajout d\'un utilisateur',
  updateEmpUserSubTitle: 'Utilisateur {{name}} {{surname}}',
  successCreatedAdmin: 'L’utilisateur a bien été créé',
  successUpdatedAdmin: 'L’utilisateur a bien été modifié',
  deleteUser: 'Supprimer l\'utilisateur',

  //titles
  title: 'Profils et utilisateurs',
  addAgencyAdminSubTitle: 'Ajout d\'un utilisateur',

  //labels
  addAgencyAdminLabel: 'Ajouter un utilisateur',
  addProfileLabel: 'Ajouter un profil',
  summaryCardLabel: 'Profils fonctionnels',

  //table headers
  identifier: 'Identifiant',
  name: 'Nom',
  rights: 'Droits',

  //icons alt texts
  sortIcon: 'Icône de tri',
  rightArrow: 'flèche droite',

  //action labels
  clicked: 'cliqué',

  //capabilities
  identity: 'Identité', //Entreprise
  beneficiary: 'Collaborateur', //Beneficiaire
  accounting: 'Facturation',
  settings: 'Paramètres',

};

export default settings;
