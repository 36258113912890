import { action, makeAutoObservable, reaction, runInAction } from 'mobx';
import { AgencyAdminModel, AgencyAdminSettingModel } from '@assets/models/agencyAdmin/AgencyAdmin.model';
import { hasAllAgencyAdminCapabilities } from './../assets/utils/security/agencyAdmins.util';
import { onCall } from '../Services/firebase.service';
import { errorStore } from './Error.store';
import { deactivateAgencyAdmin, getConnectedAgencyAdmin, updateAgencyAdmin } from '../Services/agencyAdminAPI.service';
import { CreateAgencyAdminRequest } from '@assets/requests/agencyAdmin/CreateAgencyAdminRequest';
import { createAgencyAdmin } from '../Services/agencyAdminAPI.service';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import { UpdateAgencyAdminRequest } from '@assets/requests/agencyAdmin/UpdateAgencyAdminRequest';

export class AgencyAdminStore {
  agencyAdminSelected: AgencyAdminModel | null = null;
  agencyAdminStatus: string = 'PENDING';
  isLoading: boolean = false;
  agencyAdminsList: AgencyAdminSettingModel[] = [];

  userAdminCreatedOrUpdated: string = 'PENDING';
  isLoadingCreatedOrUpdatedAgencyAdmin: boolean = false;

  userAdminDeactivated: string = 'PENDING';
  isLoadingDeactivateAgencyAdmin: boolean = false;

  agencyAdminSettings: AgencyAdminSettingModel | null = null;

  private _hasSettingsAccess: boolean = false;

  get hasSettingsAccess() {
    return this._hasSettingsAccess;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.agencyAdminSettings,
      async () => await this.checkAccessSettings(),
    );
  }

  resetAgencyAdmin() {
    runInAction(() => {
      this.agencyAdminSelected = null;
      this.agencyAdminsList = [];
      this.agencyAdminStatus = 'PENDING';
    });
  }

  async getConnectedAgencyAdmin(): Promise<void> {
    this.isLoading = true;

    try {

      const connectedAgencyAdmin: AgencyAdminModel = await getConnectedAgencyAdmin();
      runInAction(() => {
        this.agencyAdminSelected = connectedAgencyAdmin;
        this.agencyAdminStatus = 'SUCCESS';
      });
    } catch (e) {
      this.agencyAdminStatus = 'ERROR' + e;
    }
    this.isLoading = false;
  }

  async getAgencyAdminSettings(agencyId: string, agencyAdminId: string): Promise<void> {
    try {
      const agencyAdminSettings: AgencyAdminSettingModel = await onCall(
        'AGENCY_ADMIN-getAgencyAdminSettingsInformation_onCall', { agencyId, agencyAdminId });

      runInAction(() => {
        this.agencyAdminSettings = agencyAdminSettings;
      });

    } catch (error) {
      errorStore.catchError(error);
      throw error;
    }
  }

  private async checkAccessSettings(): Promise<void> {
    if (!this.agencyAdminSettings?.profile) {
      runInAction(() => {
        this._hasSettingsAccess = false;
      });
      return;
    }
    const hasAccessSettings: boolean = await hasAllAgencyAdminCapabilities(this.agencyAdminSettings.profile, ['settings.read', 'settings.write']);
    runInAction(() => {
      this._hasSettingsAccess = hasAccessSettings;
    });
  }


  async createAgencyAdmin(companyId: string, agencyId: string, adminValues: CreateAgencyAdminRequest): Promise<void> {

      this.isLoadingCreatedOrUpdatedAgencyAdmin = true;
      const confirmationResponse: ConfirmationResponse = await createAgencyAdmin(companyId, agencyId, adminValues);
      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.userAdminCreatedOrUpdated = 'SUCCESS';
          this.isLoadingCreatedOrUpdatedAgencyAdmin = false;
        }
        return;
      });

  }

  async updateAgencyAdmin(companyId: string, agencyId: string, agencyAdminId: string, adminValues: UpdateAgencyAdminRequest): Promise<void> {
    try {
      this.isLoadingCreatedOrUpdatedAgencyAdmin = true;
      const confirmationResponse: ConfirmationResponse = await updateAgencyAdmin(companyId, agencyId, agencyAdminId, adminValues);
      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.userAdminCreatedOrUpdated = 'SUCCESS';
          this.isLoadingCreatedOrUpdatedAgencyAdmin = false;
        }
        return;
      });
    } catch (e) {
      this.userAdminCreatedOrUpdated = 'ERROR' + e;
      this.isLoadingCreatedOrUpdatedAgencyAdmin = false;
    }
  }

  async deactivateAgencyAdmin(companyId: string, agencyId: string, agencyAdminId: string): Promise<void> {
    try {
      this.isLoadingDeactivateAgencyAdmin = true;
      const confirmationResponse: ConfirmationResponse = await deactivateAgencyAdmin(companyId, agencyId, agencyAdminId);
      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.userAdminDeactivated = 'SUCCESS';
          this.isLoadingDeactivateAgencyAdmin = false;
        }
        return;
      });
    } catch (e) {
      this.userAdminDeactivated = 'ERROR' + e;
      this.isLoadingDeactivateAgencyAdmin = false;
    }
  }
}
