import {
  AgencyAdminManagementUnitSettingModel,
  AgencyAdminModel,
} from '../assets/models/agencyAdmin/AgencyAdmin.model';
import { callable } from './httpWrapper.service';
import { CreateAgencyAdminRequest } from '../../../../lambdas/shared/requests/agencyAdmin/CreateAgencyAdminRequest';
import { ConfirmationResponse } from '../../../../lambdas/shared/responses/Confirmation.response';
import { UpdateAgencyAdminRequest } from '../../../../lambdas/shared/requests/agencyAdmin/UpdateAgencyAdminRequest';

export async function getConnectedAgencyAdmin(): Promise<AgencyAdminModel> {
  return await callable(
    'getConnectedAgencyAdmin',
  );
}

export async function getAllManagementUnitsSettingsOfAgencyAdmin(agencyId: string, agencyAdminId: string): Promise<AgencyAdminManagementUnitSettingModel[]> {
  return callable(
    'getAllManagementUnitsSettingsOfAgencyAdmin',
    { agencyId, agencyAdminId },
  );
}

export async function createAgencyAdmin(companyId: string, agencyId: string, request: CreateAgencyAdminRequest): Promise<ConfirmationResponse> {
  return callable(
    'createAgencyAdmin',
    { companyId, agencyId },
    request,
  );
}

export async function updateAgencyAdmin(companyId: string, agencyId: string, agencyAdminId: string, request: UpdateAgencyAdminRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateAgencyAdmin',
    { companyId, agencyId, agencyAdminId },
    request,
  );
}

export async function deactivateAgencyAdmin(companyId: string, agencyId: string, agencyAdminId: string): Promise<ConfirmationResponse> {
  return callable(
    'deactivateAgencyAdmin',
    { companyId, agencyId, agencyAdminId },
  );
}