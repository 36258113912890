import React, { useEffect } from 'react';
import ArrowRight from '../../../assets/cut-right-arrow.svg';
import DownArrow from '../../../assets/cut-bottom-arrow.svg';
import { useStyles } from './ListsStyles';
import { useSortableData } from '../../../Utils/SortGenericList';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AgencyAdminSettingModel } from '../../../assets/models/agencyAdmin/AgencyAdmin.model';
import { useNavigate } from 'react-router-dom';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';

type LocalStore = [AgenciesStore];

const AgencyAdminsList = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(['settings', 'icon-alt', 'common']);

  const navigate = useNavigate();

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {
    currentAgency,
    currentAgencyAgencyAdmins,
    currentAgencyAgencyAdminsStatus,
    currentAgencyManagementUnits,
  } = agenciesStore;

  const { items: sortedList, requestSort, sortConfig } = useSortableData(currentAgencyAgencyAdmins, {
    key: null, direction: 'asc',
  });

  useEffect(() => {
    if (currentAgency?.uid && currentAgencyAgencyAdminsStatus !== 'SUCCESS') {
      void agenciesStore.refreshAgencyAdminsWithSettings();
    }
  }, [currentAgency?.uid]);

  const handleUpdateAgencyAdmin = (agencyAdmin: AgencyAdminSettingModel) => {

    const serializedAdminDataToUpdate = {
      id: agencyAdmin.uid,
      email: agencyAdmin.email,
      firstName: agencyAdmin.firstName,
      lastName: agencyAdmin.lastName,
      profile: agencyAdmin.profile.profileId,
      managementUnits: agencyAdmin.managementUnits.map((unit) => ({
        label: unit.name,
        value: unit.uid,
      })),
      agencyAdminId: agencyAdmin.uid,
    };

    navigate('/settings/add-agencyadmin', { state: { agencyAdminData: serializedAdminDataToUpdate } });
  };

  const compareManagementUnitLists = (list1: ManagementUnitModel[],list2: ManagementUnitModel[],): boolean => {
    if (list1.length !== list2.length) {
      return false;
    }

    const sortByUid = (a: ManagementUnitModel, b: ManagementUnitModel) => a.uid.localeCompare(b.uid);

    const sortedList1 = list1.slice().sort(sortByUid);
    const sortedList2 = list2.slice().sort(sortByUid);

    return sortedList1.every((unit, index) => unit.uid === sortedList2[index].uid);
  };

  //@todo petit bug sur le tri, si on clique sur trier par profile puis sur identifiant les animations se jouent mais le tri ne s'update pas
  const getSortIcon = (key: string) => {
    return (
      <img
        src={DownArrow}
        alt={t('sortIconAlt', { ns: 'icon-alt' })}
        className={`
          ${classes.sortIcon} ${sortConfig.key === key && sortConfig.direction === 'asc'
          ? classes.sortIconAsc
          : classes.sortIconDesc
        }`}
      />
    );
  };

  return (
    ['LOADING', 'PENDING'].includes(currentAgencyAgencyAdminsStatus) ? <CircularProgress color={'primary'}/> :
      <table className={classes.table}>
        <thead>
        <tr>
          <th className={classes.tableHeader} onClick={() => requestSort('email')}>
            {t('email', { ns: 'common' })} {getSortIcon('email')}
          </th>
          <th className={classes.tableHeader} onClick={() => requestSort('firstName')}>
            {t('name', { ns: 'common' })} {getSortIcon('name')}
          </th>
          <th className={classes.tableHeader}>{t('profile')}</th>
          <th className={classes.tableHeader}>{t('managementUnits')}</th>
          <th className={classes.tableHeader}></th>
        </tr>
        </thead>
        <tbody>
        {sortedList.map((agencyAdmin: AgencyAdminSettingModel, index) => {
          const managementUnitsAreEqual = compareManagementUnitLists(
            agencyAdmin.managementUnits,
            currentAgencyManagementUnits,
          );

          return (
            <tr key={index} className={classes.tableRow} onClick={() => handleUpdateAgencyAdmin(agencyAdmin)}>
              <td className={classes.cell}>{agencyAdmin.email}</td>
              <td className={classes.cell}>{agencyAdmin.firstName + ' ' + agencyAdmin.lastName.toUpperCase()}</td>
              <td className={classes.cell}>
                <span className={classes.profileBadge}>{agencyAdmin.profile.profileName}</span>
              </td>
              <td className={classes.cell}>
                {managementUnitsAreEqual ? (
                  <span className={classes.unitBadge}>{t('all')}</span>
                ) : (
                  <>
                    {agencyAdmin.managementUnits.length > 1 ? (
                      <>
                        <span className={classes.unitBadge}>{agencyAdmin.managementUnits[0].name}</span>
                        <span className={classes.unitBadge}>+{agencyAdmin.managementUnits.length - 1}</span>
                      </>
                    ) : (
                      <span className={classes.unitBadge}>{agencyAdmin.managementUnits[0].name}</span>
                    )}
                  </>
                )}
              </td>
              <td className={classes.cell}>
                <img src={ArrowRight} alt="right arrow"/>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
  );
});

export default AgencyAdminsList;