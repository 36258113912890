import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useInstance } from 'react-ioc';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import AccountingComponent from './Accounting/AccountingComponent';
import AgencyComponent from './Agency/AgencyComponent';
import LoginPassword from './Auth/LoginPassword';
import LoginResetPassword from './Auth/LoginResetPassword';
import LoginView from './Auth/LoginView';
import ResetPasswordComponent from './Auth/ResetPassword/ResetPasswordComponent';
import BeneficiariesComponent from './Beneficiaries/BeneficiariesComponent';
import CGVsComponent from './Confidentiality/CGVs/CGVsComponent';
import LegalNoticeComponent from './Confidentiality/LegalNoticeComponent';
import MangoPayRGPD from './Confidentiality/MangoPayRGPD';
import RGPDComponent from './Confidentiality/RGPD/RGPDComponent';
import ConnectedComponent from './Connected/ConnectedComponent';
import DashboardComponent from './Dashboard/DashboardComponent';
import PaymentRequestComponent from './PaymentRequest/PaymentRequest/PaymentRequestComponent';
import AddNewRechargingComponent from './Recharging/AddNewRecharging/AddNewRechargingComponent';
import RechargingComponent from './Recharging/RechargingComponent';
import { UserConnectStore } from './Stores/UserConnect.store';
import CookiesComponent from './Cookies/CookiesComponent';
import { ContractType } from './assets/models/agencies/Agency.model';
import { AgenciesStore } from './Stores/Agencies.store';
import { PageBlock } from 'Style/Style';
import { CircularProgress, Typography } from '@material-ui/core';
import Button from './Style/MuiStyles/Button';
import { useTranslation } from 'react-i18next';
import VouchersComponent from './Vouchers/VouchersComponent';
import VouchersInvoicesComponent from './Accounting/VouchersInvoicesComponent';
import SettingsPage from './Settings/SettingsPage';
import ProfilsParametersComponent from './Settings/Profiles/Profiles';
import AgencyAdminsPageComponent from './Settings/AgencyAdmins/AgencyAdminsPage';
import AddOrUpdateAgencyAdminPage from 'Settings/AgencyAdmins/AddorUpdateAgencyAdminPage';


interface IPrivateRouteProps {
  children: any;
  redirectTo?: string;
}

const PrivateRoute: FunctionComponent = observer((props: IPrivateRouteProps) => {
  const userConnectStore: UserConnectStore = useInstance<UserConnectStore>(UserConnectStore);

  return userConnectStore.isConnected
    ? props.children
    : <Navigate to={props.redirectTo ?? '/login'}/>
    ;
});

interface IContractTypeGuardedRouteProps extends IPrivateRouteProps {
  requiredContractTypes: ContractType[];
}

const ContractTypeGuardedRoute: FunctionComponent<IContractTypeGuardedRouteProps> = observer((props: IContractTypeGuardedRouteProps) => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  if (!agenciesStore.currentAgency) {
    return <PageBlock><CircularProgress color={'primary'}/></PageBlock>;
  }

  return props.requiredContractTypes.includes(agenciesStore.currentAgency.contractType)
    ? props.children
    : (
      <PageBlock>
        <Typography style={{ marginBottom: '2em' }}>
          {t('pageAccessDenied')}
        </Typography>

        <Button
          onClick={() => navigate('/dashboard')}
          type="button"
          variant="contained"
          color="primary"
        >
          {t('pageRedirectToHome')}
        </Button>
      </PageBlock>
    )
    ;
});

const AppModule: FunctionComponent = () => {

  return (
    <Routes>
      <Route path="/reload" element={null}/>

      <Route path="/login" element={<LoginView/>}/>
      <Route path="/login/password" element={<LoginPassword/>}/>
      <Route path="/login/reinitialisation" element={<LoginResetPassword/>}/>

      <Route path="/reset-password" element={<ResetPasswordComponent/>}/>
      <Route path="/register-admin" element={<ResetPasswordComponent/>}/>

      <Route path="/cookies" element={<CookiesComponent/>}/>
      <Route path="/legalnotice" element={<LegalNoticeComponent/>}/>
      <Route path="/rgpd" element={<RGPDComponent/>}/>
      <Route path="/cgv" element={<CGVsComponent/>}/>
      <Route path="/mangopayrgpd" element={<MangoPayRGPD/>}/>

      <Route element={<PrivateRoute><ConnectedComponent><Outlet/></ConnectedComponent></PrivateRoute>}>
        <Route path="/dashboard" element={<DashboardComponent/>}/>
        <Route path="/beneficiaries" element={<BeneficiariesComponent/>}/>
        <Route path="/agency/*" element={<AgencyComponent/>}/>
        <Route path="/agency" element={<Navigate to="/agency/information" replace/>}/>
        <Route path="/paymentrequest" element={<PaymentRequestComponent/>}/>

        <Route path="/settings" element={<SettingsPage/>}/>
        <Route path="/settings/agencyadmins" element={<AgencyAdminsPageComponent/>}/>
        <Route path="/settings/profiles" element={<ProfilsParametersComponent/>}/>
        <Route path="/settings/add-agencyadmin" element={<AddOrUpdateAgencyAdminPage/>}/>

        <Route element={<ContractTypeGuardedRoute
          requiredContractTypes={['STANDARD', 'GEOGRAPHIC_RESTRICTION']}><Outlet/></ContractTypeGuardedRoute>}>
          <Route path="/reloading" element={<RechargingComponent/>}/>
          <Route path="/addnewrecharging" element={<AddNewRechargingComponent/>}/>
          <Route path="/continueOrder" element={<AddNewRechargingComponent/>}/>

          <Route path="/accounting/*" element={<AccountingComponent/>}/>
          <Route path="/accounting" element={<Navigate to="/accounting/invoices" replace/>}/>
        </Route>

        <Route element={<ContractTypeGuardedRoute
          requiredContractTypes={['PUBLIC_MEAL_SUBSIDY']}><Outlet/></ContractTypeGuardedRoute>}>
          <Route path="/vouchers-transactions" element={<VouchersComponent/>}/>
          <Route path="/vouchers-invoices" element={<VouchersInvoicesComponent/>}/>
        </Route>
      </Route>

      { /*Redirect to a "default" route if no other routes match*/}
      <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
    </Routes>
  );
};

export default AppModule;
