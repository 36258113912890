const beneficiariesfromcsv = {
  EMPTY_COLUMN_NAME: 'La colonne n°{{index}} n\'a pas de nom',
  UNKNOWN_COLUMN: 'La colonne {{name}} n\'est pas reconnue',
  MISSING_TESTSET_KEYS: 'La colonne {{name}} n\'a pas été trouvée dans le fichier fourni',
  DUPLICATE_REGISTRATION_NUMBER: 'le matricule {{duplicate}} est présent plusieurs fois dans le fichier',
  DUPLICATE_EMAIL: 'le mail {{duplicate}} est présent plusieurs fois dans le fichier',
  FILE_EMPTY: 'Le fichier semble vide',
  NOT_A_STRING: 'le champ "{{field}}" ne doit pas contenir de chiffre.',
  NOT_A_NUMBER: 'le champ "{{field}}" doit être numérique.',
  IS_REQUIRED: 'le champ "{{field}}" est requis.',
  NOT_AN_ALPHANUMERIC: 'le champ "{{field}}" doit être alphanumérique.',
  NOT_AN_EMAIL: 'le champ "{{field}}" doit être au format email.',
  NOT_A_DATE: 'la date "{{field}}" doit être cohérente.',
  NOT_A_VALID_IBAN: 'Cet IBAN n’est pas valide',
  NOT_A_VALID_REGISTRATION_NUMBER: 'Ce matricule n’est pas valide',
  NOT_RESPECT_LENGTH: 'le champ "{{field}}" doit contenir entre {{minLength}} et {{maxLength}} caractères.',
  NOT_RESPECT_MAX_LENGTH: 'le champ "{{field}}" doit contenir un maximum de {{maxLength}} caractères.',
  NOT_VALUE_BETWEEN: 'le champ "{{field}}" doit être compris entre {{minValue}} et {{maxValue}}.',
  NOT_YES_NO_VALUE: 'le champ "{{field}}" doit être égal à "O" ou "N".',
  NOT_ACCEPTED_TITLE: 'le champ "{{field}}" doit être égal à "M." ou "Mme".',
  NOT_ACCEPTABLE_RANGE_DATE: 'La Date "{{field}}" doit être comprise entre les {{lowerRange}} mois précédents et les {{upperRange}} mois suivants aujourd\'hui',
  REGISTRATION_NUMBER_ALREADY_TAKEN: 'Le matricule ou l’adresse mail est déjà attribué à un autre bénéficiaire.',
  EMAIL_ALREADY_TAKEN: 'le champ "{{field}}", l’adresse mail est déjà attribué à un autre bénéficiaire..',
  REGISTRATION_NUMBER_AND_EMAIL_ALREADY_TAKEN: 'le matricule et l’adresse mail sont déjà attribués à un autre bénéficiaire..',
  INVALID_BENEFICIARY_CLASSIFICATION_FORMAT: 'Zone Classification : Valeur requise',
  UNKNOWN_BENEFICIARY_CLASSIFICATION_MONOCLASS: 'Zone Classification : Cette zone devrait être égale à “1”',
  UNKNOWN_BENEFICIARY_CLASSIFICATION_MULTICLASS: 'Zone Classification : Il n’y a pas de classe active avec ce code - {{expectedValue}}',
  UNKNOWN_MANAGEMENT_UNIT: 'Zone Unité de gestion : Il n’y a pas d\'unité de gestion active avec ce code - {{expectedValue}}',
  UNKNOWN_ORGANIZATION: 'Zone {{field}} : Ce code semble invalide - {{expectedValue}}',
  titleAddedAnalyse_one: '{{count}} collaborateur va être ajouté',
  titleAddedAnalyse_other: '{{count}} collaborateurs vont être ajoutés',
  titleAddedAnalyse_zero: '{{count}} collaborateurs vont être ajoutés',
  titleUpdateAnalyse_one: '{{count}} collaborateur va être mis à jour',
  titleUpdateAnalyse_other: '{{count}} collaborateurs vont être mis à jour',
  titleUpdateAnalyse_zero: '{{count}} collaborateurs vont être mis à jour',
  titleLineError_one: '{{count}} erreur à la ligne {{lineNumber}}',
  titleLineError_other: '{{count}} erreurs à la ligne {{lineNumber}}',
  titleAdded_one: '{{count}} collaborateur a été ajouté',
  titleAdded_other: '{{count}} collaborateurs ont été ajoutés',
  titleAdded_zero: '{{count}} collaborateurs ont été ajoutés',
  titleUpdate_one: '{{count}} collaborateur a été mis à jour',
  titleUpdate_other: '{{count}} collaborateurs ont été mis à jour',
  titleUpdate_zero: '{{count}} collaborateurs ont été mis à jour',
  titleFailed_one: '{{count}} collaborateur n’a pas été créé ou mis à jour',
  titleFailed_other: '{{count}} collaborateurs n’ont pas été créé ou mis à jour',
  titleFailed_zero: '{{count}} collaborateurs n’ont pas été créé ou mis à jour',
  subtitleResultSuccess: 'Parfait !',
  subtitleResultSuccessSecond: 'Félicitations, le fichier a été intégré avec succès.',
  subtitleResultSuccessWithFailed_one: 'Le traitement s’est terminé avec {{count}} avertissement.',
  subtitleResultSuccessWithFailed_other: 'Le traitement s’est terminé avec {{count}} avertissements.',
  subtitleResultError: 'Oops, nous avons rencontrés un problème avec pictogramme',
  subtitleResultErrorSecond: 'Suite à une erreur technique, votre fichier n’a pas pu être importé. Merci de bien vouloir lancer à nouveau l’import',

  errorSnackBar: '😞 Désolé, ce fichier génère une erreur, essayez d\'en sélectionner un nouveau',
};

export default beneficiariesfromcsv;
