import { COLORS, PageBlock } from '../../Style/Style';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import BackButton from '../../Component/BackButton/BackButtonComponnent';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Chip, ListItemText, MenuItem, Select } from '@material-ui/core';
import { InputSelect } from '../../Style/MuiStyles/Select/Select';
import Downarrow from '../../assets/down-arrow';
import { useStyles } from './AddAgencyAdminPageStyle';
import Button from '../../Style/MuiStyles/Button';
import { useInstances } from 'react-ioc';
import { AgencyAdminStore } from '../../Stores/AgencyAdmin.store';
import { FormikHelpers, useFormik } from 'formik';
import BeneficiaryTextField from '../../Beneficiaries/BeneficiaryDetails/FormParts/BeneficiaryTextFieldComponent';
import * as Yup from 'yup';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { getListOfProfiles } from './../../assets/utils/security/agencyAdmins.util';
import { useNavigate, useLocation } from 'react-router-dom';
import OpenSnackbar from '../../Style/MuiStyles/OpenSnackbar';
import { AgencyAdminProfileDefinition } from '@assets/models/security/agencyAdmins.model';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';
import GlobalDeleteConfirmationDialog from 'Component/Dialog/GlobalDeleteConfirmationDialog';

type PartialManagementUnit = {
  label: string;
  value: string;
};

const AddOrUpdateAgencyAdminPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const agencyAdminData = location.state?.agencyAdminData || null;
  const isUpdateMode = Boolean(agencyAdminData);

  const classes = useStyles({ isUpdateMode });
  const { t } = useTranslation(['settings', 'common']);
  const [agencyAdminStore] = useInstances(AgencyAdminStore);
  const [agenciesStore] = useInstances(AgenciesStore);
  const { currentAgencyManagementUnits, currentAgency } = agenciesStore;
  const [showSucessSnackbar, setShowSucessSnackbar] = useState(false);
  const [listOfProfiles, setListOfProfiles] = useState<AgencyAdminProfileDefinition[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const goBack = () => {
    navigate(`/settings/agencyadmins`);
  };

  const listOfManagementUnits: PartialManagementUnit[] = useMemo(() => {
    return currentAgencyManagementUnits.map(
      (unit: ManagementUnitModel): PartialManagementUnit => ({
        label: unit.name,
        value: unit.uid,
      }),
    );
  }, [currentAgencyManagementUnits]);

  useEffect(() => {
    const loadProfiles = async () => {
      const result: AgencyAdminProfileDefinition[] = await getListOfProfiles(currentAgency?.uid);
      setListOfProfiles(result);
    };
    loadProfiles();
  }, []);

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom' as 'bottom',
      horizontal: 'left' as 'left',
    },
    getContentAnchorEl: null,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email invalide').required('Requis'),
    firstname: Yup.string().required('Requis'),
    lastname: Yup.string().required('Requis'),
    selectedProfilValue: Yup.string().required('Requis'),
    selectedUnitValues: Yup.array().of(Yup.string()).min(1, 'Au moins une unité de gestion doit être sélectionnée'),
  });

  const initialValues = {
    email: agencyAdminData?.email || '',
    firstname: agencyAdminData?.firstName || '',
    lastname: agencyAdminData?.lastName || '',
    selectedProfilValue: agencyAdminData?.profile || '',
    selectedUnitValues: agencyAdminData?.managementUnits?.map((unit) => unit.value) || [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
      setSubmitting(true);

      const formattedValues = {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        profileId: values.selectedProfilValue,
        managementUnitsIds: values.selectedUnitValues,
      };

      if (isUpdateMode) {
        delete formattedValues.email;
        await agencyAdminStore.updateAgencyAdmin(currentAgency.companyId, currentAgency.uid, agencyAdminData.agencyAdminId, formattedValues);
      } else {
        await agencyAdminStore.createAgencyAdmin(currentAgency.companyId, currentAgency.uid, formattedValues);
      }
      if (agencyAdminStore.userAdminCreatedOrUpdated === 'SUCCESS') {
        setShowSucessSnackbar(true);
        await agenciesStore.refreshAgencyAdminsWithSettings();
        setTimeout(() => {
          navigate(`/settings/agencyadmins`);
        }, 1000);
      }

    },
  });

  const handleDeactivateUserAdmin = async () => {

    await agencyAdminStore.deactivateAgencyAdmin(currentAgency.companyId, currentAgency.uid, agencyAdminData.agencyAdminId);

    if (agencyAdminStore.userAdminDeactivated === 'SUCCESS') {
      setIsDialogOpen(false);
      await agenciesStore.refreshAgencyAdminsWithSettings();
      navigate(`/settings/agencyadmins`);
    }
    setIsDialogOpen(false);
  };

  return (
    <PageBlock>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton linkTo={'/settings/agencyadmins'}/>
          <h1>{isUpdateMode ? t('updateEmpUserSubTitle', {
            name: agencyAdminData.firstName, surname: agencyAdminData.lastName.toUpperCase(),
          }) : t('addEmpUserSubTitle')}</h1>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
        <h2 className={classes.subTitle}>IDENTITÉ</h2>
        <div className={classes.identityWrapper}>
          <div style={{ width: 'calc(33% - 10px)' }}>
            <BeneficiaryTextField id="email" title="Email" required formik={formik} disabled={isUpdateMode}/>
          </div>
          <div style={{ width: 'calc(33% - 10px)' }}>
            <BeneficiaryTextField
              id="firstname"
              title="Nom"
              label={'Prénom'}
              required
              formik={formik}
              placeholder={'Prénom'}
            />
          </div>
          <div style={{ width: 'calc(33% - 10px)' }}>
            <BeneficiaryTextField
              id="lastname"
              title="Prénom"
              required
              label={'Nom'}
              placeholder={'Nom'}
              formik={formik}
            />
          </div>
        </div>

        <h2 className={classes.subTitle}>PROFIL</h2>
        <div>
          <Select
            name="selectedProfilValue"
            value={formik.values.selectedProfilValue}
            onChange={formik.handleChange}
            input={<InputSelect title="Profil" required/>}
            onBlur={formik.handleBlur}
            IconComponent={(props) => (
              <div style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}</div>
            )}
            label="Profil"
            MenuProps={MenuProps}
          >
            {listOfProfiles.map((profile, index) => (
              <MenuItem key={index} value={profile.uid}>
                {profile.name}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.selectedProfilValue && formik.errors.selectedProfilValue && (
            <div
              style={{
                color: COLORS.error,
                fontSize: '14px',
                marginTop: '10px',
                marginLeft: '16px',
              }}
            >
              {formik.errors.selectedProfilValue}
            </div>
          )}
        </div>

        <h2 className={classes.subTitle}>UNITÉS DE GESTION</h2>
        <Select
          multiple
          name="selectedUnitValues"
          value={formik.values.selectedUnitValues}
          onChange={formik.handleChange}
          input={<InputSelect title={t('managementUnit')} required/>}
          onBlur={formik.handleBlur}
          IconComponent={(props) => (
            <div style={{ marginTop: '0px' }}>
              {Downarrow({ svgColor: COLORS.textPrimary, ...props })}
            </div>
          )}
          renderValue={(selected: string[]) => (
            <div>
              {selected.map((value: string) => (
                <Chip
                  size="small"
                  key={value}
                  label={listOfManagementUnits.find((item) => item.value === value)?.label}
                  className={classes.selectedItemClass}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {listOfManagementUnits.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <Checkbox
                className={classes.checkedSelectUnit}
                checked={formik.values.selectedUnitValues.indexOf(item.value) > -1}
              />
              <ListItemText primary={t(item.label)}/>
            </MenuItem>
          ))}
        </Select>

        {formik.touched.selectedUnitValues && formik.errors.selectedUnitValues && (
          <div
            style={{
              color: COLORS.error,
              fontSize: '14px',
              marginLeft: '16px',
            }}
          >
            {formik.errors.selectedUnitValues}
          </div>
        )}

        <div className={classes.buttonContainer}>
          {isUpdateMode && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                className={classes.buttonDelete}
                onClick={() => setIsDialogOpen(true)}
              >
                {t('deleteUser')}
              </Button>
            </div>
          )}
          <div className={classes.saveButtonContainer}>
            <Button variant="contained" color="secondary" type="button" onClick={goBack}>
              {t('cancel', { ns: 'common' })}
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
              isFetching={agencyAdminStore.isLoadingCreatedOrUpdatedAgencyAdmin}
            >
              {isUpdateMode ? t('validate', { ns: 'common' }) : t('add', { ns: 'common' })}
            </Button>
          </div>
        </div>
      </form>

      <OpenSnackbar
        vertical={'top'}
        horizontal={'right'}
        isOpen={showSucessSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSucessSnackbar(false)}
        type={'success'}
      >
        <div style={{ backgroundColor: 'transparent', color: 'black' }}>
          {isUpdateMode ? t('successUpdatedAdmin') : t('successCreatedAdmin')}
        </div>
      </OpenSnackbar>

      <GlobalDeleteConfirmationDialog
        isActive={isDialogOpen}
        onClose={setIsDialogOpen}
        onConfirm={handleDeactivateUserAdmin}
        loading={agencyAdminStore.isLoadingDeactivateAgencyAdmin}
        confirmText="Oui, supprimer"
        cancelText="Non, annuler"
        deleteMessage="Êtes-vous certain de vouloir supprimer cet utilisateur ?"
      />

    </PageBlock>
  );
});

export default AddOrUpdateAgencyAdminPage;