import { callable } from './httpWrapper.service';
import { PaybackModel } from '../assets/models/paybacks/Payback.model';
import { GetBeneficiaryPaybacksRequest } from '../assets/requests/paybacks/GetBeneficiaryPaybacks.request';
import { convertPaybackFromCentsToEuros } from '../assets/utils/paybacks/paybacks.utils';

export async function getBeneficiaryPaybacks(beneficiaryId: string, request: GetBeneficiaryPaybacksRequest): Promise<PaybackModel[]> {
  return callable(
    'getBeneficiaryPaybacks',
    { beneficiaryId },
    request,
  ).then(paybacks => paybacks.map(convertPaybackFromCentsToEuros));
}
