import { CURRENT_ENV } from '../assets/api-mapping';
import { firebaseConfig } from '../assets/firebase.config';
import { getFunctions, httpsCallable, connectFunctionsEmulator, Functions } from 'firebase/functions';
import { FirebaseApp, initializeApp as initializeAppService } from 'firebase/app';
import {
  getFirestore as getFirestoreService,
  collectionGroup,
  connectFirestoreEmulator,
  doc,
  getDoc as getDocService,
  DocumentData,
  Firestore,
  Unsubscribe,
  onSnapshot,
  getDocs,
  OrderByDirection,
  WhereFilterOp,
  collection,
  query,
  where,
  orderBy, Timestamp,
} from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { GetBeneficiaryQrCodeStringRequest } from '@assets/requests/beneficiaries/GetBeneficiaryQrCodeString.request';
import { CreateAgencyOrderRequest } from '@assets/requests/orders/CreateAgencyOrder.request';
import { AddVoucherRestrictionRequest } from '@assets/requests/discreteVouchers/AddVoucherRestrictionRequest';
import { VoucherRestrictionModel } from '@assets/models/discreteVouchers/VoucherRestriction.model';
import { DeleteVoucherRestrictionRequest } from '@assets/requests/discreteVouchers/DeleteVoucherRestrictionRequest';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import {
  GetBeneficiaryVoucherRestrictionRequest,
} from '@assets/requests/discreteVouchers/GetBeneficiaryVoucherRestrictionRequest';
import { Query } from '@firebase/firestore';
import { HydratedVoucherModel } from '@assets/models/discreteVouchers/DiscreteVoucher.model';
import { GetAgencyVouchersRequest } from '@assets/requests/discreteVouchers/GetAgencyVouchers.request';
import { ConfirmAgencyOrderResponse } from '@assets/responses/orders/ConfirmAgencyOrder.response';
import {
  GetAllAgencyAdminsWithSettingsInformationRequest,
} from '@assets/requests/agencyAdmin/GetAllAgencyAdminsWithSettingsInformation.request';
import { AgencyAdminSettingModel } from '@assets/models/agencyAdmin/AgencyAdmin.model';
import {
  GetAgencyAdminSettingsInformationRequest,
} from '@assets/requests/agencyAdmin/GetAgencyAdminSettingsInformation.request';

export type QueryWhereElement<T> = {
  fieldName: keyof T;
  operator: WhereFilterOp;
  value: unknown;
}

type Order<T> = {
  fieldName: keyof T,
  direction: OrderByDirection
}

type BackendClient = {
  'AUTH-checkIsActivatedUser_onCall': {
    request: { email: string },
    response: { isActivated: boolean }
  },
  'BENEFICIARIES-getBeneficiaryQrCodeString_onCall': {
    request: GetBeneficiaryQrCodeStringRequest,
    response: string
  },
  'ORDERS-createAgencyOrder_onCall': {
    request: CreateAgencyOrderRequest,
    response: ConfirmAgencyOrderResponse
  },
  'DISCRETE_VOUCHERS-addVoucherRestriction_onCall': {
    request: AddVoucherRestrictionRequest,
    response: VoucherRestrictionModel
  },
  'DISCRETE_VOUCHERS-getBeneficiaryVoucherRestriction_onCall': {
    request: GetBeneficiaryVoucherRestrictionRequest,
    response: VoucherRestrictionModel[]
  },
  'DISCRETE_VOUCHERS-deleteVoucherRestriction_onCall': {
    request: DeleteVoucherRestrictionRequest,
    response: ConfirmationResponse
  },
  'DISCRETE_VOUCHERS-getAgencyVouchers_onCall': {
    request: GetAgencyVouchersRequest,
    response: HydratedVoucherModel[]
  },
  'AGENCY_ADMIN-getAllAgencyAdminsWithSettingsInformation_onCall': {
    request: GetAllAgencyAdminsWithSettingsInformationRequest,
    response: AgencyAdminSettingModel[]
  },
  'AGENCY_ADMIN-getAgencyAdminSettingsInformation_onCall': {
    request: GetAgencyAdminSettingsInformationRequest,
    response: AgencyAdminSettingModel
  }
}

let firebaseApp: FirebaseApp | undefined;
let firestore: Firestore | undefined;
let functions: Functions | undefined;

let isAppCheckInitialized: boolean = false;
let isConnectedToFunctionsEmulator: boolean = false;
let isConnectedToFirestoreEmulator: boolean = false;

export function initializeApp(): FirebaseApp {
  const app: FirebaseApp = firebaseApp ?? initializeAppService(firebaseConfig[CURRENT_ENV]);
  if (!isAppCheckInitialized) {
    isAppCheckInitialized = true;
    if (CURRENT_ENV === 'LOCAL') {
      (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    }
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_KEY),
      isTokenAutoRefreshEnabled: true,
    });

  }
  return app;
}

export function getFirebaseFunctions() {
  functions = functions ?? getFunctions(initializeApp(), 'europe-west3');
  if (CURRENT_ENV === 'LOCAL' && !isConnectedToFunctionsEmulator) {
    isConnectedToFunctionsEmulator = true;
    connectFunctionsEmulator(functions, 'localhost', 4089);
  }
  return functions;
}

export function getFirestore() {
  firestore = firestore ?? getFirestoreService(initializeApp());
  if (CURRENT_ENV === 'LOCAL' && !isConnectedToFirestoreEmulator) {
    isConnectedToFirestoreEmulator = true;
    connectFirestoreEmulator(firestore, 'localhost', 8080);
  }
  return firestore;
}

export function subscribeToDocument<T extends DocumentData = DocumentData>(path: string, onUpdate: (data: T | undefined) => void): Unsubscribe {
  const docRef = doc(getFirestore(), path);

  return onSnapshot(docRef, (doc) => {
    onUpdate(doc.data() as T);
  });
}

export async function getDoc<T extends DocumentData = DocumentData>(path: string): Promise<T | undefined> {
  const docSnapshot = await getDocService(doc(getFirestore(), path));
  return docSnapshot.data() as T;
}

export async function getDocsWhere<T>(collectionName: string, queryWhere: QueryWhereElement<T>[] = [], order?: keyof T | Order<T>): Promise<T[]> {
  const c = collection(getFirestore(), collectionName);
  const q = constructQuery(c, queryWhere, order);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(d => d.data() as T);
}

export async function getWhereInCollectionGroup<T>(collectionGroupName: string, queryWhere: QueryWhereElement<T>[] = [], order?: keyof T | Order<T>): Promise<T[]> {
  try {
    const collectionGroupQuery = collectionGroup(getFirestore(), collectionGroupName);
    const query = constructQuery(collectionGroupQuery, queryWhere, order);
    const querySnapshot = await getDocs(query);
    return querySnapshot.docs.map(d => d.data() as T);
  } catch (e) {
    console.log('getWhereInCollectionGroup error', e);
    return [];
  }
}

export async function getAllDocs<T>(collectionName: string): Promise<T[]> {
  const c = collection(getFirestore(), collectionName);
  const querySnapshot = await getDocs(c);
  return querySnapshot.docs.map(d => d.data() as T);
}

function constructQuery<T>(originalQuery: Query, queryWhere: QueryWhereElement<T>[] = [], order?: keyof T | Order<T>): Query {
  let q = originalQuery;
  for (const whereOption of queryWhere) {
    q = query(q, where(whereOption.fieldName as string, whereOption.operator, whereOption.value));
  }

  if (!!order) {
    if (typeof order === 'string') {
      q = query(q, orderBy(order));
    } else {
      const o = order as Order<T>;
      q = query(q, orderBy(o.fieldName as string, o.direction));
    }
  }

  return q;
}

export async function onCall<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(functionName: FuncName, request: Req): Promise<Res> {
  return httpsCallable(getFirebaseFunctions(), functionName, { timeout: 540 * 1000 })(request).then(result => result.data) as Res;
}

export function convertFSTimestampToMilli(obj) {
  if (obj instanceof Timestamp) {
    return obj.toMillis();
  } else if (obj instanceof Array) {
    return obj.map(convertFSTimestampToMilli);
  } else if (obj instanceof Date) {
    return obj.getTime();
  } else if (typeof obj === 'object' && obj !== null) {
    const convertedObj = {};
    Object.entries(obj).forEach(([key, value]) => {
      convertedObj[key] = convertFSTimestampToMilli(value);
    });
    return convertedObj;
  }
  return obj;
}