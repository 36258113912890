const common = {
  required: 'Requis',
  // generic
  previousLabel: 'Précédent',
  nextLabel: 'Suivant',
  modify: 'Modifier',
  add: 'Ajouter',
  cancel: 'Annuler',
  save: 'Enregistrer',
  close: 'Fermer',
  validate : 'Valider',
  send: 'Envoyer',
  email: 'Email',
  all_male: 'Tout',
  all_female: 'Toute',
  all_males: 'Touts',
  all_females: 'Toutes',
  // Civility
  mr: 'M.',
  mrs: 'Mme',
  name: 'Nom',
  // Months
  januaryLabel: 'Janvier',
  februaryLabel: 'Février',
  marchLabel: 'Mars',
  aprilLabel: 'Avril',
  mayLabel: 'Mai',
  juneLabel: 'Juin',
  julyLabel: 'Juillet',
  augustLabel: 'Août',
  septemberLabel: 'Septembre',
  octoberLabel: 'Octobre',
  novemberLabel: 'Novembre',
  decemberLabel: 'Décembre',
  // breadcrumb
  dashboard: 'Accueil',
  beneficiaries: 'Collaborateurs',
  reloading: 'Rechargement',
  newreloading: 'Rechargement',
  continueorder: 'Rechargement',
  agency: 'Paramètres',
  accounting: 'Factures',
  settings : 'Paramètres',
  paymentrequest: 'Remboursements',
  addnewrecharging: 'Rechargement',
  'vouchers-transactions': 'Transactions',
  // dropzone
  dropzone: {
    titleEmpty: 'Glissez ici votre fichier',
    readFile: 'Lancer la lecture du fichier',
    link: {
      where: 'ou ',
      search: 'Recherchez ',
      yourFile: 'votre fichier à envoyer',
      anotherFile: 'un autre fichier',
    },
  },
  pageAccessDenied: 'Vous n\'avez pas accès à cette page.',
  pageRedirectToHome: 'Revenir à l\'accueil',
};

export default common;
